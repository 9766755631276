@tailwind base;
@tailwind components;
@tailwind utilities;


iframe {
  width: 100%;
  height: 100%;
}

html { scroll-behavior: smooth; }

.zIndex {
  transform: translate3d(0, 0, 1px);
}

@font-face {
  font-family: "Prestige";
  src: url("./fonts/prestige.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face { 
  font-family: "Cardinal-Fruit-Medium";
  src: url("./fonts/Cardinal-Fruit-Medium.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Prestige-elite-m";
  src: url("./fonts/prestige.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "freehouse-regular";
  src: url("./fonts/freehouse-regular.ttf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Saa-f-d";
  src: url("./fonts/SaaSeriesFD.woff");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Saa-a-d";
  src: url("./fonts/saa-a-d.otf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Saa-a-d";
  src: url("./fonts/saa-a-d.otf");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Saa-c-d";
  src: url("./fonts/Saa-Series-C-D.otf");
  font-display: swap;
  font-style: normal;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}